import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="content">
      <h1>404 - NOT FOUND</h1>
      <p>The page you're looking for could not be found.</p>
      <p>Please select a page from the navigation menu.</p>
    </div>
  </Layout>
)

export default NotFoundPage
